import { forceScreen } from "../constants/config";

const today = new Date();
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const accountInitialState = localStorage.getItem('harinaCajaArrozAccountState') ?
    JSON.parse(localStorage.getItem('harinaCajaArrozAccountState')) :
    {
        errorLogin: false,
        isLogged: false,
        playedDate: today.getTime(),
        playedTimes: 0,
        profile: null,
        tomorrow: tomorrow.getTime(),
        windedPrize: null
    };

export const reducerAccount = (initialState = accountInitialState, action) => {
    const state = Object.assign({}, initialState);
    const { type, payload } = action;

    switch (type) {
        case 'LOGIN':
            state.profile = payload;
            state.isLogged = true;
            break;

        case 'ERROR':
            state.errorLogin = !state.errorLogin;
            break;

        case 'LOGOUT':
            state.profile = null;
            state.isLogged = false;
            state.errorLogin = false;
            setTimeout(() => {
                localStorage.clear();
            }, 1500);
            break;

        case 'RESETDATE':
            state.playedDate = today.getTime();
            state.tomorrow = tomorrow.getTime();
            state.playedTimes = 0;
            break;

        case 'RESETPRIZE':
            state.windedPrize = null;
            break;

        case 'SETPRIZE':
            const { playedTimes: pTimes, playedDate: pDate, tomorrow: tDate } = state;
            if(pDate < tDate && pTimes < 5) {
                state.playedTimes = forceScreen ? 0 : pTimes + 1;
                state.windedPrize = payload;
            } else if(pDate >= tDate) {
                state.playedDate = today.getTime();
                state.tomorrow = tomorrow.getTime();
                state.windedPrize = payload;
            } else {
                state.windedPrize = null;
            }
            break;

        default:
            break;
    }

    localStorage.setItem('harinaCajaArrozAccountState', JSON.stringify(state));

    if(type === 'LOGIN') {
        const cHost = window.location.host;
        const urlToBack =  cHost.indexOf('harinadearroz.com.mx') > -1
            ? 'https://harinadearroz.com.mx/play'
            : (cHost.indexOf('localhost:3000') > -1
            ? 'https://localhost:3000/play'
            : 'https://harinadearroz.heliko.space/play');
        setTimeout(() => {
            window.location.replace(urlToBack);
        }, 0);
    }

    return state;
};
